import { useEffect, useMemo, useCallback, useState, useContext, createContext } from 'react'
import { useRouter } from 'next/router'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useCollection, useDocument } from 'react-firebase-hooks/firestore'
import { useToasts, useClipboard } from '@geist-ui/react'

import firebase from 'firebase/app'
import { captureException } from 'utils/sentry'

import { find, get, includes, isPlainObject, isArray, isEmpty, mapValues } from 'lodash'

import { getPreviewUrl, getShareUrl } from 'utils/helpers'

export const useQueryTab = (key = 'tab') => {
  const router = useRouter()
  const { query: { [key]: tab = '' } } = router

  return tab
}

export const useCurrentUser = () => {
  const [user] = useAuthState(firebase.auth())

  return user
}

export const useCurrentUserShape = () => {
  const  { uid, displayName, email, photoURL } = useCurrentUser()
  return { uid, displayName, email, photoURL }
}

export const useIsCurrentUserIn = dataSet => {
  const user = useCurrentUser()

  if (isPlainObject(dataSet)) return !!dataSet[user.uid]
  if (isArray(dataSet)) return includes(dataSet, user.uid) || !!find(dataSet, ['uid', user.uid])

  return false
}

export const useIsDocAdmin = doc => {
  const user = useCurrentUser()

  return doc?.data().roles[user.uid] === 'admin'
}

export const useUserDocument = () => {
  const { uid } = useCurrentUser()
  const [document, loading, error] = useDocument(
    firebase.firestore()
      .doc(`users/${uid}`)
  )

  if (error) captureException(error)

  return [document, loading, error]
}

export const useLogout = () => {
  const logout = useCallback(event => {
    event?.preventDefault()
    event?.stopPropagation()

    return firebase.auth().signOut()
  }, [])

  return logout
}

export const useUser = () => {
  const router = useRouter()
  const [user, loading, error] = useAuthState(firebase.auth())

  useEffect(() => {
    const { asPath, route } = router

    if (loading) return

    if (!user && route !== '/preview') {
      try {
        const returnPath =
          route === '/signin' ||
          route === '/_error' ||
          route === '/' ? '' : asPath

        router.push(`/signin${returnPath && `?returnPath=${encodeURIComponent(returnPath)}`}`)
      } catch(error) {
        captureException(error)
      }
    }
  }, [user, loading])

  return [user, loading, error]
}

const useCurrentDocId = () => {
  const router = useRouter()
  const { query: { doc } } = router

  return doc
}


const getTimestamps = () => {
  const time = firebase.firestore.FieldValue.serverTimestamp()
  return { create: time, update: time }
}

export const useCreateBoardDoc = () => {
  const workspaceId = useCurrentWorkspaceId()
  const userShape = useCurrentUserShape()

  return async ({ title, description, template, publicBoard, anonymousBoard, participants, participantsUsers }) => {  
    const ref = await firebase.firestore().collection('boards').add({
      title,
      description,

      template,
      visibility: publicBoard ? 'public' : 'private',
      anonymous: anonymousBoard,

      workspace: workspaceId,
      creator: userShape.uid,
  
      participants: [userShape.uid, ...participants],
      users: { [userShape.uid]: userShape, ...participantsUsers },
      roles: { [userShape.uid]: 'admin', ...mapValues(participantsUsers, () => 'member') },

      timestamps: getTimestamps(),
    })

    return ref
  }
}

export const useCreateNoteDoc = (boardDocument) => {
  const userShape = useCurrentUserShape()

  return async ({ lane, note, anonymous }) => {  
    boardDocument.ref.collection('notes').add({
      content: note,
      lane: lane,
      status: 'unread',

      timestamps: getTimestamps(),
      ...(anonymous ?
        { anonymous } :
        { author: userShape.uid, users: { [userShape.uid]: userShape }
      }),
    })
  }
}

export const useCurrentWorkspaceId = () => {
  const router = useRouter()
  const { query: { workspace_id } } = router

  return workspace_id || firebase.auth().currentUser.uid
}

export const useIsPersonalWorkspace = () => {
  const workspaceId = useCurrentWorkspaceId()
  return workspaceId === firebase.auth().currentUser.uid
}

export const useCurrentWorkspaceDocument = () => {
  const workspaceId = useCurrentWorkspaceId()
  const showToast = useShowToast()
  const router = useRouter()

  const [document, loading, error] = useDocument(
    firebase.firestore().doc(`workspaces/${workspaceId}`),
    { snapshotListenOptions: { includeMetadataChanges: true } }
  )

  useEffect(() => {
    if (error) {
      const { code, message } = error

      switch (code) {
        case 'permission-denied': {
          showToast({ type: 'error', text: message })
          router.push('/')
          break
        }
        default:
          captureException(error)
          break
      }
    }
  }, [error])

  return [document, loading, error]
}

export const useYourWorkspaceBoardsCollection = () => {
  const workspaceId = useCurrentWorkspaceId()
  const [querySnapshot, loading, error] = useCollection(
    firebase.firestore()
      .collection('boards')
      .where('participants', 'array-contains', firebase.auth().currentUser.uid)
      .where('workspace', '==', workspaceId)
      .orderBy('timestamps.create', 'desc')
  )

  if (error) captureException(error)

  return [querySnapshot, loading, error]
}

export const useWorkspaceBoardsCollection = (queries = []) => {
  const workspaceId = useCurrentWorkspaceId()

  const ref = useMemo(() => {
    let query = firebase.firestore()
      .collection('boards')
      .where('workspace', '==', workspaceId)
      .orderBy('timestamps.create', 'desc')

    for (const where of queries) {
      query = query.where(...where)
    }

    return query
  }, [])  

  const [querySnapshot, loading, error] = useCollection(ref)

  if (error) captureException(error)

  return [querySnapshot, loading, error]
}

export const userSharedRetrosCollection = () => {
  const [querySnapshot, loading, error] = useCollection(
    firebase.firestore()
      .collection('boards')
      .where('participants', 'array-contains', firebase.auth().currentUser.uid)
      .where('creator', '!=', firebase.auth().currentUser.uid)
      .orderBy('creator', 'desc')
      .orderBy('timestamps.create', 'desc')
  )

  if (error) captureException(error)

  return [querySnapshot, loading, error]
}

export const useRetroDocument = () => {
  const router = useRouter()
  const docId = useCurrentDocId()
  const showToast = useShowToast()

  const [document, loading, error] = useDocument(
    firebase.firestore().doc(`boards/${docId}`),
    { snapshotListenOptions: { includeMetadataChanges: true } }
  )

  useEffect(() => {
    if (error) {
      const { code, message } = error

      switch (code) {
        case 'permission-denied': {
          showToast({ type: 'error', text: message })
          router.push('/')
          break
        }
        default:
          captureException(error)
          break
      }
    }
  }, [error])

  return [document, loading, error]
}

export const useRetroNotesCollection = (queries = []) => {
  const docId = useCurrentDocId()

  const ref = useMemo(() => {
    let query = firebase.firestore()
      .collection(`boards/${docId}/notes`)
      .orderBy('timestamps.create', 'desc')

    for (const where of queries) {
      query = query.where(...where)
    }

    return query
  }, [])  

  const [querySnapshot, loading, error] = useCollection(ref)

  if (error) captureException(error)

  return [querySnapshot, loading]
}

export const useActionsCollection = (queries = []) => {
  const workspaceId = useCurrentWorkspaceId()
  const ref = useMemo(() => {
    let query = firebase.firestore()
      .collectionGroup('actions')
      .where('links.workspace', '==', workspaceId)
      .orderBy('timestamps.create', 'desc')

    for (const where of queries) {
      if (!isEmpty(where)) {
        query = query.where(...where)
      }
    }

    return query
  }, [queries, workspaceId])

  const [querySnapshot, loading, error] = useCollection(ref)

  if (error) captureException(error)

  return [querySnapshot, loading]
}

export const useInviteActionsCollection = (queries = []) => {
  const ref = useMemo(() => {
    let query = firebase.firestore()
      .collectionGroup('actions')
      .where('external', '==', true)
      .where('assigners', 'array-contains', firebase.auth().currentUser.uid)
      .where('links.workspace', '!=', firebase.auth().currentUser.uid)
      .orderBy('links.workspace', 'desc')
      .orderBy('timestamps.create', 'desc')

    for (const where of queries) {
      if (!isEmpty(where)) {
        query = query.where(...where)
      }
    }

    return query
  }, [queries])

  const [querySnapshot, loading, error] = useCollection(ref)

  if (error) captureException(error)

  return [querySnapshot, loading]
}

export const useBoardActionsCollection = () => {
  const docId = useCurrentDocId()
  const [querySnapshot, loading, error] = useCollection(firebase.firestore().collection(`boards/${docId}/actions`))

  if (error) captureException(error)

  return [querySnapshot, loading]
}


export const useShowToast = () => {
  const [,setToast] = useToasts()
  return setToast
}

export const useBoolean = initialValue => {
  const [value, setValue] = useState(initialValue)

  const on = useCallback(() => setValue(true), [])
  const off = useCallback(() => setValue(false), [])

  return [value, on, off]
}

export const SidebarCollapseContext = createContext(false)
export const useSidebarCollapse = () => {
  const { value, collapseSidebar } = useContext(SidebarCollapseContext)
  return [value, collapseSidebar]
}

const urlByAction = {
  'create_share_link': getShareUrl,
  'create_preview_link': getPreviewUrl,
}

const tokenByAction = {
  'create_share_link': 'tokens.share',
  'create_preview_link': 'tokens.preview',
}

export const usePwdLink = (board, remoteAction) => {
  const [processing, setProcessing] = useState(false)
  const showToast = useShowToast()
  const { copy } = useClipboard() 

  if (!board) return { generate: () => {}, revoke: () => {}, processing }

  const regenerate = async () => {
    setProcessing(true)
    const { data } = await firebase.app().functions('europe-west1').httpsCallable(remoteAction)({ boardId: board.id })
    setProcessing(false)

    const action = {
      name: 'Copy to clipboard',
      handler: () => copy(urlByAction[remoteAction](data))
    }

    showToast({ text: 'Link was generated', type: 'success', actions: [action] })
    return
  }

  const generate = async () => {
    const tokenKey = tokenByAction[remoteAction]
    const token = get(board.data(), tokenKey)

    if (!token) {
      return regenerate()
    }

    copy(urlByAction[remoteAction ](token))
    showToast({ text: 'Link copied to clipboard', type: 'success' })
  }

  const revoke = () => board.ref.update(tokenByAction[remoteAction], firebase.firestore.FieldValue.delete())

  return { generate, revoke, regenerate, processing }
}

const parseValue = value => {
  try {
    return JSON.parse(value)
  } catch(error) {
    return value
  }
}

export const useLocalStorage = (key, defaultValue) => {
  const [value, setValue] = useState(() => {
    if (process.browser && window.localStorage.getItem(key)) {
      const storageValue = window.localStorage.getItem(key)

      if (!storageValue) return defaultValue

      return parseValue(storageValue)
    }

    return defaultValue
  })

  const update = useCallback(value => {
    const data = JSON.stringify(value)
    window.localStorage.setItem(key, data)

    setValue(value)
  }, [key])

  useEffect(() => {
    const handleLocalStorageChange = event => {
      if (event.storageArea === window.localStorage) {
        if (key === event.key && event.newValue) {
          setValue(parseValue(event.newValue))
        }
      }
    }

    window.addEventListener('storage', handleLocalStorageChange)
    return () => window.removeEventListener('storage', handleLocalStorageChange)
  }, [key])

  return [value, update]
}
