import { first, words, capitalize } from 'lodash'

export const getSystemTheme = () => {
  if (process.browser && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    return 'dark'
  }

  return 'light'
}

export const getAvatarName = name => words(name).map(first).join('')
export const getCompanyName = email => capitalize(email?.split('@')[1].split('.')[0])

const getPwdUrl = (action, pwd) => `${window.location.origin}/${action}?pwd=${pwd}`

export const getPreviewUrl = token => getPwdUrl('preview', token)
export const getShareUrl = token => getPwdUrl('join', token)
